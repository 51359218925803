var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"content-class":"elevation-0","persistent":"","max-width":"1120px","overlay-opacity":"0.8","value":_vm.vm.showAllocationDialog,"retain-focus":false}},[_c('ConfirmClaimDialog',{ref:"confirmDialog"}),_c('div',{staticClass:"d-flex overflow-hidden pa-4"},[(_vm.vm.selectedMarketKey)?_c('div',{staticClass:"nft-card border-radius-16"},[_c('hover-image',{attrs:{"keyInfo":_vm.vm.selectedMarketKey,"poolStore":_vm.vm.poolStore}})],1):_vm._e(),_c('card-hover',{staticClass:"ml-4 full-width pa-4 border-radius-16 elevation-0",class:_vm.$vuetify.theme.dark ? 'light-black-bg' : 'background-1',attrs:{"blendOverlay":false,"blurLevel":'blur-none',"disable-hover":true,"disableShadow":false,"disableBorder":true}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Your NFT allocation")]),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.vm.approving || _vm.vm.swaping},on:{"click":function($event){return _vm.vm.setSelectedMarketKey(null)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('card-hover',{staticClass:"main-card mt-6 overflow-hidden pa-4 border-radius-16",attrs:{"blendOverlay":false,"blurLevel":'blur-none',"disable-hover":true,"disableShadow":true,"disableBorder":true}},[_c('v-data-table',{staticClass:"purchases-table font-weight-bold",attrs:{"headers":_vm.purchaseHeaders,"items":_vm.vm.purchases,"loading":_vm.vm.loadingTable,"disable-pagination":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.text,class:h.align === 'center' ? 'text-center' : 'text-left'},[_c('div',{staticClass:"normal-caption-text"},[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.purchase.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.purchase.amount,2))+" "+_vm._s(item.poolStore.tokenName)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.vm.claimAirdrop)?_c('span',[_vm._v(" Automated Distribute ")]):(item.wasFinalized)?_c('span',[_c('v-icon',{attrs:{"color":"success","size":"20"}},[_vm._v("mdi-checkbox-marked-circle-outline")])],1):_c('div',[_c('ClaimButton',{attrs:{"item":item}})],1)])]}},{key:"item.purchase.validAfterDate",fn:function(ref){
var item = ref.item;
return [(item.purchase.validAfterDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("ddmmyyyyhhmm")(item.purchase.validAfterDate))+" ")]):_c('span',[_vm._v("TBA")])]}},{key:"item.purchase.ethAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.purchase.ethAmount))+" "+_vm._s(_vm.vm.tradeToken)+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }