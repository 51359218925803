




































































































import {} from '@/modules/market/viewmodels/market-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject, Ref } from 'vue-property-decorator'
import { IdoPoolDetailViewModel } from '../viewmodels/ido-pool-detail-viewmodel'

@Observer
@Component({
  components: {
    HoverImage: () => import('@/modules/market/components/hover-image.vue'),
    ConfirmClaimDialog: () => import('./confirm-claim-dialog.vue'),
    ClaimButton: () => import('@/modules/market/components/claim-button.vue'),
  },
})
export default class AllocationDialog extends Vue {
  @Inject() vm!: IdoPoolDetailViewModel
  @Ref('confirmDialog') confirmDialog: any

  purchaseHeaders = [
    { text: '#', value: 'index', sortable: false },
    { text: 'Token Allocation', value: 'purchase.amount', sortable: false },
    { text: 'Trade Amount', value: 'purchase.ethAmount', sortable: false },
    { text: 'Date', value: 'purchase.validAfterDate', sortable: false },
    { text: 'Withdraw', value: 'action', sortable: false, align: 'center' },
  ]

  openConfirmDialog() {
    this.confirmDialog.open()
  }
}
